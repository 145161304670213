.container-log{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login{
    width: 440px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px 40px 31px 40px ;
    background:  #2E2E2E;
}
@media (max-width: 480px) {
    .login {
        width: auto;
        padding: 40px 20px 31px 20px;
    }
}


.login div:last-child {
    display: flex;
    justify-content: center;
}
.login div:last-child span {
    color: #7A7978;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.login .dashed {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.login .dashed::before,
.login .dashed::after {
    content: "";
    border-bottom: 1px dashed #7A7978;
    flex: 1;
    height: 1px;
}

.login .dashed p {
    display: block;
    background: #2E2E2E;
    padding: 0 10px;
    color: #7A7978;
}

.input-container{
    gap: 10px;
    max-width: 360px;
    height: 50px;
    display: flex;
    padding: 15px;
    align-items: center;
    align-self: stretch;
    border-radius: 5px;
    background:  #202020;
}
.input{
    border: 1px solid #0000;
    flex: 1;
    background:  #202020;
    color:#fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}

.input-container.error {
    border: 1px solid rgba(255, 0, 0, .5);
    /*background-color: rgba(255, 0, 0, .3);*/
}
.input.error {
    border: 1px solid rgba(255, 0, 0, .3);
    background-color: rgba(255, 0, 0, .3);
}
.input:focus{
    outline: none
}

.login .forget{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
}
.login .forget a{
    color:  #7A7978;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */


}

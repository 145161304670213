.feature-slider-pagination {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;
}
.feature-slider-pagination ul {
    display: flex;
    margin: 0;
    padding: 0;
}
.feature-slider-pagination li {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 8px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    list-style-type: none;
}
.feature-slider-pagination li div {
    position: relative;
    border-radius: 5px;
    color: white;
    width: 40px;
    height: 8px;
    overflow: hidden;
}
.feature-slider-pagination li div:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    width: 40px;
    height: 8px;
    text-align: center;
    /*opacity: .5;*/
    background-color: rgba(64,64,64,.6);
}
.feature-slider-pagination li div:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 0;
    height: 8px;
    background-color: rgba(121,121,120,1);
    transition: width 0s;
}

.feature-slider-pagination li.active-dot div:after {
    width: 100%;
    height: 8px;
    transition: width 3.3s;
}

body {
    background-color: #242424;
    overflow-x: hidden;
}
.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1440px;
}
.container-md {
    margin: 0 auto;
    max-width: 960px;
    padding: 0 10px;
}
.container-lg {
    margin: 0 auto;
    max-width: 1150px;
    padding: 0 10px;
    width: 100%;
}
.container-sm {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 720px;
    width: 100%;
}

.image-container{
    overflow: hidden; /* Ensure zoomed images are clipped within the container */
    width: 100%; /* Adjust width as needed */
    height:  100%;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    z-index: 0;
}

.slider-container {
    position: relative;
}
.slider-image {
    position: relative;
    width: 100%;
    aspect-ratio: 5/2;
}

@media (max-width: 450px) {
    .slider-container {
        height: 560px; /* Adjust the height as needed */
    }

    .slider-image {
        aspect-ratio: 4/3; /* Set the aspect ratio of the image */
        height: 500px; /* Set the height of the image */
        background-position: center; /* Center the background image */
        background-size: cover; /* Cover the entire container with the background image */
    }
}

.slider-image img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    object-fit: cover;
}


.swiper-overlay-gradient-top {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #00000080 15%, transparent 30%);
}


.swiper-overlay-gradient-bottom {
    position: absolute;
    bottom: -1px;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(33, 33, 33, 0), rgba(36,36,36,.9));
}
.swiper-overlay-gradient-bottom:before {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 20%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(33, 33, 33, 0), rgba(36,36,36,1));
}

.swiper-parallax-content {
    position: absolute;
    width: 100%;
    bottom: 20px;
}

.swiper-parallax-wrapper {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 20px;
}

.swiper-parallax-movie-name {
    margin-bottom: 5px;
    font-size: 14px;
    color: #999;
}

.swiper-parallax-episode-name {
    margin-bottom: 20px;
    line-height: 1;
    font-size: 24px;
    color: #fff;
}

.swiper-parallax-buttons {
    display: flex;
    gap: 10px;
}


.feature-control-box {
    /*width: 100%;*/
    position: absolute;
    bottom: 20px;
    right: 0;

}
.feature-control-box .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;

}
.feature-control-box .wrapper > div {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: max-content;
}
.feature-control-box .wrapper {

}

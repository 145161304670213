.cart-latest{
    position: relative;
    padding: 0;
    display: block;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
    user-select: none;
}
.cart-latest * {user-select: none;}

@media (max-width: 640px) {
    .cart-latest{display: none;}
}
.cart-latest .wrapper{
    width: 100%;
    height: 80%;
    position: absolute;
    opacity: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
}
.cart-latest .wrapper:hover{opacity: 1;}


.cart-latest .ep-number{
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 25px;
    background: rgba(0, 0, 0, 0.80);
    padding: 5px 10px 5px 10px;
    user-select: none;
}
.cart-latest .ep-number * {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    user-select: none;
}


.cart-latest .icon{
    position: absolute;
    right: 10px;
    top: 15px;
    user-select: none;
}
.cart-latest .wrapper .icon > div:last-child {
    margin-top: 20px;
    user-select: none;
}
.cart-latest .wrapper .icon > div svg:hover{
    fill: #fff;
    user-select: none;
}

.cart-latest .img {

}
.cart-latest .img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16/9;
    user-select: none;
}

.cart-latest .content{position: absolute;bottom: 0;padding: 10px; user-select: none }
.cart-latest .content .title {opacity: 1;transition-delay: 0s;transition-duration: 0.4s; color: #fff; user-select: none}
.cart-latest .content .title span{display: -webkit-box !important;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden; color: #fff;font-size: 16px; user-select: none}

.cart-latest .wrapper .duration {opacity: 1;transition-delay: 0s;transition-duration: 0.4s;}
.cart-latest .wrapper .duration a{color: #fff;font-size: 16px;}




@media (min-width: 640px) {
    .cart-latest-mobile{
        display: none;
    }
}

.cart-latest-mobile{
    position: relative;

}


.cart-latest-mobile .ep-number{
    position: absolute;
    font-size: 12px;
    color: #DFDFDF;
    background: #000000CC;
    padding: 5px 8px;
    border-bottom-right-radius: 5px;
    user-select: none;
}

.cart-latest-mobile .title{
    font-size: 12px;
    color: #DFDFDF;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    user-select: none;
}
.cart-latest-mobile .duration{
    font-size: 12px;
    color: #666666;
    user-select: none;
}


.ongoing {position: relative;}
.cart-ongoing {position: relative;overflow: hidden;}
.cart-ongoing * {user-select: none;}
@media (max-width: 640px) {
    .cart-ongoing{display: none;}
}

.cart-ongoing .status{
    position: absolute;
    top: 10px;
    left: 10px;
    background: #FF6B00;
    border-radius: 5px;
    padding: 0 5px;
}
.cart-ongoing .status span{
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;;
}

.cart-ongoing img{
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    aspect-ratio: 2/3;
}
.cart-ongoing img img{width: 100%;aspect-ratio: 2/3;}
.cart-ongoing .overlay{
    position: absolute;
    opacity: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    padding: 10px;
    min-height: 100%;
    -webkit-transition: all 400ms ease-out;
    -moz-transition: all 400ms ease-out;
    -o-transition: all 400ms ease-out;
    -ms-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    aspect-ratio: 2/3;
    background: #202020;
    overflow: hidden;
    border-radius: 4px;
}
.cart-ongoing .about{
    display: flex;
    flex-flow: column;
    flex: 1;
    aspect-ratio: 2/3;
    overflow: hidden;
}

.cart-ongoing .overlay:hover{
    opacity: 1;
    cursor: pointer;
}
.cart-ongoing .about .title span{
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
}
.cart-ongoing .about .rating {
    margin-top: 5px;
    display: flex;
    align-items: center
}
.cart-ongoing .about .rating span{
    color: #FF4418;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%
}
.cart-ongoing .about .desc {
    margin-top: 5px;
    /*height: 73px;*/
    overflow-y: scroll;
    line-height: 100%;
}
@media (max-width: 620px) {
    .cart-ongoing .about .desc {
        height: 30%;
        overflow-y: scroll;
    }
}

/* for firefox browser*/

.cart-ongoing .about .desc {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #ccc #000; /* Sets the thumb and track color to black */
}

.cart-ongoing .about .desc::-webkit-scrollbar {
    width: 1px;
    height: 100%;
    background-color: #000;

}
.cart-ongoing .about .desc::-webkit-scrollbar-thumb {
    background: #000;
}

.cart-ongoing .about .desc span{
    color: #A7A5A5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

}
.cart-ongoing .about .sub {

}
.cart-ongoing .about .sub div{
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cart-ongoing .about .sub >div span:first-child  {
    color: #A7A5A5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

}
.cart-ongoing .about .sub >div span:last-child  {
    color: #DFDFDF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}
.cart-ongoing  .buttons{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
}

.cart-ongoing .buttons:hover{
    cursor: pointer;
}
.cart-ongoing .buttons .svg {
    display: inline-block;
    border-radius: 5px;
    padding: 5px;
    background: #00b3ff;
}


.cart-year * {user-select: none;}
.cart-year .img{width: 100%;height: 100%;}
.cart-year .img img {width: 100%;aspect-ratio: 2/3;}
.cart-year .img:hover {cursor: pointer;}
.cart-year .about {background: #000000;padding: 20px;}
.cart-year .about .rating span{
    color: #FF6600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%
}
.cart-year .about .title{margin-top: 10px;}
.cart-year .about .title a{
    color: #A7A5A5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media (max-width: 620px) {
    .cart-year .about .title a {font-size: 14px;}
}

@media (min-width: 620px) {
    .cart-year .about .buttons a {display: none;}
}
@media (max-width: 620px) {
    .cart-year .about .buttons span {display: none;}
}




.card-favorite * {user-select: none}
.card-favorite:hover {cursor: pointer;}
.card-favorite .year span{
    color: #00b3ff ;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 19.6px */
}
.card-favorite .img:last-child{filter: blur(3px);}

.card-favorite .about{display: flex;margin-top: 10px;gap: 10px;}
.card-favorite .about span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #8F8D8D;
}
.card-favorite .about div:last-child {
    padding-left: 10px;
    border-left: 2px dashed #8F8D8D;
}


.card-popular-list {
    gap: 10px;
    display: flex;
    padding: 10px 10px 10px 0;
    align-items: center;
    align-self: stretch;
}
.card-popular-list * {user-select: none;}
.card-popular-list:hover{border-radius: 5px;cursor: pointer;background: #2E2E2E;}
.card-popular-list:hover .count {border-left: 2px solid #A7A5A5;}
.card-popular-list:hover .about .title a {color: #00b3ff;}
.card-popular-list:hover .svg path {stroke: #00b3ff;fill: #00b3ff;}
.card-popular-list:hover .svg g path {stroke: #fff;}
.card-popular-list .count{
    display: flex;
    width: 42px;
    border-left: 2px solid #00b3ff;
    justify-content: center;
    align-items: center;
    align-self: stretch;
}
.card-popular-list .count span{
    color: #DFDFDF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.card-popular-list .about .title a{
    color: #C1BFBF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
}
@media (min-width: 480px) {
    .card-popular-list .about .title a {font-size: 14px;}
}

.card-popular-list .about:last-child div:nth-child(2) span {margin-right: 10px;}
.card-popular-list .about:last-child span{
    color: #C1BFBF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.card-common * {user-select: none;}
.card-common .img {aspect-ratio: 2/3;cursor: pointer;}
.card-common .img img {height: 100%;width: 100%;object-fit: cover;}
.card-common .about:last-child div {
    color: #666666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 16.8px */
}
.card-common .rating {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.card-common .rating span{
    color: #FF6600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.card-common .title a {
    color: #A7A5A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}



.card-author * {user-select: none;}
.card-author .img {margin-top: 20px;width: 100%;height: 100%;}
.card-author .img img {aspect-ratio: 2/3;object-fit: cover;border-radius: 5px;}


.card-author-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-author-header  .img {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50px;
    object-fit: cover;
    overflow: hidden;
}
.card-author-header  .img img{
    width: 100%;
    height: 100%;
}
.card-author-header  .name {
    color: #A7A5A5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.card-author-header .age {
    color:#666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}



/* popular page css start */

.Popular {}

.card-popular {display: flex;gap: 20px;border-bottom: 2px dashed #0E0E0E;padding: 20px 0;}
.card-popular * {user-select: none;}
.card-popular .img{height: 200px;}
.card-popular .img img{object-fit: cover;aspect-ratio: 2/3;}



@media (max-width: 450px) {
    .card-popular .img{

    }
    .card-popular .title{
        font-size: 14px;
    }
    .card-popular .icons div{
        display: none;
    }
}
.card-popular .img img{
    height: 100%;
    width: 100%;

}
.card-popular .img:hover{
    cursor: pointer;
}
.card-popular .title{
    color: #A7A5A5;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card-popular .title:hover{
   cursor: pointer;
}
@media (max-width: 500px) {
    .card-popular .icons{
        display: none;
    }
}

.card-popular .icons{
    margin-top: 10px;
    display: flex;
    align-items: center;

}
.card-popular .icons div {display: flex;align-items: center;gap: 5px;padding-right: 5px;}
.card-popular .icons div:first-child {color: #FF6600;}
@media (max-width: 600px) {
    .card-popular .icons > div:nth-child(2), .card-popular .icons > div:nth-child(3) {display: none;}
}

.card-popular .icons > div:nth-child(2), .card-popular .icons > div:nth-child(3) {
    padding-left: 5px;
    border-left: 1px dashed #000; /* Adjust the border style as needed */
    color: #A7A5A5;
}


.card-popular .about{
    margin-top: 10px;
}
@media (max-width: 600px) {
    .card-popular .about{
        margin-top: 5px;
    }
}

.card-popular .about span:first-child {
    color: #A7A5A5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}
.card-popular .about span:last-child {
    color: #DFDFDF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}


/* popular card tabs css start */

.popular__tabs{
    -webkit-tap-highlight-color: transparent;
}
.popular__tab-list{
    display: flex;
}
.popular_react-tabs__tab {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    padding: 10px 15px;
    font-weight: 700;

}
.popular_react-tabs__tab__selected{
    background-color: #000;
    border-color: #aaa;
    color: #fff;


}
.popular_react-tabs__tab__selected {
    outline: none;
}
@media (max-width: 480px) {
    .popular_react-tabs__tab {
        padding: 5px 10px;
    }
}




.this-year{
    position: relative;
}

.this-year-prev, .this-year-next {
    background: rgba(0, 0, 0, 0.80);
    padding: 20px 10px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute; /* Position buttons absolutely within the container */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for the button's height */
    border: 1px solid #4A4A4A;
}

@media (max-width: 465px) {
    .this-year-prev, .this-year-next {
        padding: 12px 6px;

    }
}

.this-year-prev:hover, .this-year-next:hover {
    background-color: #7A7978;
}

.this-year-prev {
    left: -15px; /* Place the prev button on the left side */
}

.this-year-next {
    right: -15px; /* Place the next button on the right side */
}

.authors-slider-container .slick-list {
    margin: 0 -17px;
}
.authors-slider-container .slick-slide > div {
    padding: 0 20px
}
.ofAuthor-slider-container .slick-list {
    margin: 0 -3px;
}
.ofAuthor-slider-container .slick-slide > div {
    padding: 0 5px
}



.slider-over-navigation {
    position: relative;
}

.slider-over-navigation .author-prev, .slider-over-navigation .author-next {
    z-index: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.80);
    padding: 15px 5px;
    border-radius: 5px;
    cursor: pointer;
    position: absolute; /* Position buttons absolutely within the container */
    top: 70%; /* Center vertically */
    border: 1px solid #4A4A4A;
    transition: transform .3s ease, opacity .3s ease;
}
.slider-over-navigation:hover .author-prev,
.slider-over-navigation:hover .author-next {
    z-index: 2;
    opacity: 1;
    transform: translateX(0) translateY(-50%);
}
.slider-over-navigation .author-prev {
    transform: translateX(100px) translateY(-50%);
}
.slider-over-navigation .author-next {
    transform: translateX(-100px) translateY(-50%);
}

.slider-over-dots {
    bottom: -20px
}
.slider-over-dots li {
    width: 40px;
}

.slider-over-dots li.slick-active button:before,
.slider-over-dots li.slick-active button:after{
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 1);
}
.slider-over-dots li:not(.slick-active) button:before,
.slider-over-dots li:not(.slick-active) button:after{
    opacity: .5 !important;
    background-color: rgba(0, 0, 0, 1);
}
.slider-over-dots li:not(.slick-active) button:hover:before,
.slider-over-dots li:not(.slick-active) button:hover:after{
    opacity: .5 !important;
    background-color: rgba(255, 255, 255, 1);
}
.slider-over-dots li button,
.slider-over-dots li button:before,
.slider-over-dots li button:after{
    content: "" !important;
    border-radius: 5px;
    width: 40px !important;
    height: 7px !important;
    transition: all .3s ease;
}

.author-prev:hover, .author-next:hover {
    background-color: #7A7978;
}

.author-prev {
    left: -10px; /* Place the prev button on the left side */
}

.author-next {
    right: -10px; /* Place the next button on the right side */
}



















.card-common-mobile {


}
@media (min-width: 640px) {
    .card-common-mobile{
        display: none;
    }
}
.card-common-mobile .img{
    aspect-ratio: 2/3;


}
.card-common-mobile .img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.card-common-mobile .about:last-child div{
    color: #666666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 16.8px */
}
.card-common-mobile .rating{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.card-common-mobile .rating span{
    color: #FF6600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.card-common-mobile .title a{
    color: #A7A5A5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
}




